import axios from "axios";

//dev
// export const api = axios.create({
//     baseURL: 'http://localhost:3333',
//     withCredentials: true
// })

//dev aws
// export const api = axios.create({
//     baseURL: 'https://api.mailoumeet.com',
//     withCredentials: true
// })

//homolog
export const api = axios.create({
    baseURL: 'https://api.agendamailou.com',
    withCredentials: true
})  

// prodgit 
// export const api = axios.create({
//     baseURL: process.env.NODE_ENV == 
//         'development' ? 'http://localhost:3333' : 
//         'https://api.agendarapido.com',
//     withCredentials: true
// })


